/* global window:true */
/* eslint no-underscore-dangle: 0 */

import createStore from 'redux-zero'
import { applyMiddleware } from 'redux-zero/middleware'
import { connect } from 'redux-zero/devtools'

const windowCheck = typeof window !== `undefined`

const initialState = {
  avatar_path: '',
  cdn: '',
  adminUser: '',
  alerts: [],
  auth: false,
  currentUser: null,
  loading: false,
  fetchingTasksAndTasksData: false,
  drawer: windowCheck ? window.innerWidth > 480 : true,
  masterKeys: [],
  statusMenus: {},
  userStatusMap: {},
  taskTypesView: {},
  locales: [
    'en',
    'he',
    'fr',
    'es',
    'it',
    'pt',
    'ru',
    'lt',
    'lv',
    'mn',
    'tr',
    'bg',
    'sr',
    'ro',
    'sl',
    'hr',
    'ar',
  ],
  taskTypeMap: {
    General: 0,
    Report: 1,
    'External link': 2,
  },
  actionPagesMap: {},
  subTaskType: ['Text', 'Product', 'Image', 'Link'],
  userRoles: [],
  userAreas: [],
  showUserModal: false,
  showTasksModal: false,
  editTaskTypeModal: false,
  showCategoryModal: false,
  showTaskTypeModal: false,
  showItemModal: false,
  showSelectorModal: false,
  showDeleteUserWarningModal: false,
  showDeleteTaskWarningModal: false,
  showDeleteCategoryWarningModal: false,
  showSubmitTaskWarningModal: false,
  showCopyTaskModal: false,
  showCloneTaskModal: false,
  showAssigneeTaskModal: false,
  showProductModal: false,
  showValidationPassword: false,

  selectedTask: { data_oid: null, task_id: null, data_order: null },
  selectedTypeTask: {},
  selectedType: {},
  selectedUser: {},
  selectedRow: { image: '' },
  selectedCategory: {},
  selectedSubTask: {},
  selectedProduct: {},
  selected_rid: null,
  selected_sid: null,
  selected_item: null,
  selected_todate: null,
  selected_fromdate: null,
  nonExpandable: [],

  taskTags: [],
  tags: [],
  selectedTag: {},
  userRidsDetails: {},
  permissions: [],
  creatingTaskOfType: null,
  creatingSubTaskOfType: null,
  thisWeek: false,
  enableAddTasks: false,
  tasks: [],
  taskTypes: [],
  reportTasks: [],
  tasksData: null,
  subTasks: {},
  subTasksToDelete: {},

  cloneMode: null,
  subRidIds: [],
  enableFileUpload: false,
  enableDateSubmit: false,
  users: [],
  fetchedUsers: {},
  editUser: false,
  editTask: false,
  editCategory: false,
  editSubTask: false,
  editItem: false,
  editProduct: false,
  deleteingUser: false,
  enableSaveTasksChanges: false,
  enableSelectorSubmit: false,
  newCategories: {},
  openSelector: true,
  tabNum: 0,
  categoryImages: [],

  changedTasks: [],
  expandedCategory: [],
  itemModalFormType: null,

  showImageCarouselModal: false,
  carouselModalImages: [],

  selectedPost: {},
  showPostModal: false,
  editPost: false,
  postRef: [],
  posts: [],
  retailsConfig: {},
  notificationSelectedUsers: [],

  groupLevelMap: {},
  showGroupModal: false,
  showDeleteGroupWarningModal: false,
  editGroup: false,
  selectedGroup: {},
  groups: {},
  retails: [],
  reportFetchRangesMap: {},
  reports: [],
  etl_actions: [],

  retailTags: [],
  storesIds: [],
  storeDepartments: null,
  dataConfigs: null,
  taskSelectedUser: {},
  retailsDataConfigs: {},
  usersDataIds: [],
}
const middlewares = connect ? applyMiddleware(connect(initialState)) : []
const store = createStore(initialState, middlewares)

export default store
