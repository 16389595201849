// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-create-pop-js": () => import("./../../../src/pages/create-pop.js" /* webpackChunkName: "component---src-pages-create-pop-js" */),
  "component---src-pages-elements-index-js": () => import("./../../../src/pages/elements/index.js" /* webpackChunkName: "component---src-pages-elements-index-js" */),
  "component---src-pages-external-etls-js": () => import("./../../../src/pages/external-etls.js" /* webpackChunkName: "component---src-pages-external-etls-js" */),
  "component---src-pages-groups-js": () => import("./../../../src/pages/groups.js" /* webpackChunkName: "component---src-pages-groups-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notification-js": () => import("./../../../src/pages/notification.js" /* webpackChunkName: "component---src-pages-notification-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-reports-jsx": () => import("./../../../src/pages/reports.jsx" /* webpackChunkName: "component---src-pages-reports-jsx" */),
  "component---src-pages-retail-config-js": () => import("./../../../src/pages/retailConfig.js" /* webpackChunkName: "component---src-pages-retail-config-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tasks-js": () => import("./../../../src/pages/tasks.js" /* webpackChunkName: "component---src-pages-tasks-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */)
}

